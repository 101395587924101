var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-width" }, [
    _vm.settingIpfs
      ? _c(
          "div",
          { staticClass: "d-flex flex-column align-center justify-center" },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "greenPrimary" },
            }),
            _c("span", { staticClass: "mew-heading-2 textDark--text" }, [
              _vm._v(_vm._s(_vm.$t("ens.ipfs-processing"))),
            ]),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("ens.ipfs-processing-description"))),
            ]),
          ],
          1
        )
      : _vm._e(),
    !_vm.settingIpfs
      ? _c(
          "div",
          { staticClass: "d-flex flex-column" },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _c("input", {
                  ref: "zipInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: ".zip" },
                  on: { change: _vm.fileChange },
                }),
                _c("mew-button", {
                  attrs: {
                    "btn-style": "transparent",
                    title: _vm.$t("ens.upload-my-website"),
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$refs.zipInput.click()
                    },
                  },
                }),
              ],
              1
            ),
            _c("mew-input", {
              attrs: {
                label: _vm.$t("ens.content-hash"),
                placeholder: _vm.$t("ens.enter-hash"),
                "error-messages": _vm.error,
              },
              on: { input: _vm.setHash },
              model: {
                value: _vm.ipfs,
                callback: function ($$v) {
                  _vm.ipfs = $$v
                },
                expression: "ipfs",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    !_vm.settingIpfs
      ? _c(
          "div",
          { staticClass: "d-flex align-center justify-center mt-3" },
          [
            _c("mew-button", {
              attrs: {
                disabled: !_vm.isValidIPFS || !_vm.ipfs,
                title: _vm.$t("ens.set-hash"),
                "btn-size": "xlarge",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.setIpfs(_vm.ipfs)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }